<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="11"
        >
          <div v-if="!isCreateMode">
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <b-dropdown-item
                    @click="showConfirmOrCancelDelete()"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
          <validation-observer
            v-slot="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <!-- Form: Info Form -->
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(onSubmit)"
            >

              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <span class="d-inline cardTitle">Informações</span>
                  </div>
                </b-card-title>
                <hr class="mt-1">
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <!-- Field: First Name -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- Profile Name -->
                      <validation-provider
                        #default="validationContext"
                        name="profile_name"
                        rules="required"
                      >
                        <b-form-group
                          label="Nome do Plano"
                          label-for="profile_name"
                        >
                          <b-form-input
                            id="profile_name"
                            v-model="profile.name"
                            :state="getValidationState(validationContext)"
                            placeholder="Nome do Plano"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- Profile Situation -->
                      <situation-select
                        id="profileSituation"
                        v-model="profile.situation"
                        label="Situação do Plano"
                        placeholder="Selecione a Situação do Plano"
                        :readonly="false"
                        :required-field="true"
                        @ready="syncLoad.ready('situatioProfile')"
                      />
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <span class="d-inline cardTitle">Módulos</span>
                  </div>
                </b-card-title>
                <hr class="mt-1 mb-0">
                <b-card-body
                  class="pt-0 px-1"
                >
                  <b-row
                    v-for="(item, index) in profile.modules"
                    :key="item.id"
                    :class="['p-1', index % 2 === 0 ? 'isEven' : '']"
                  >
                    <b-col
                      cols="12"
                      class="d-flex align-items-center"
                    >
                      <span class="moduleName">
                        {{ item.name }}
                      </span>
                      <span class="px-1">
                        <b-form-checkbox
                          :key="item.id"
                          v-model="profile.modules[index].value"
                          :value="true"
                          :disabled="profile.modules[index].key === 'Bidding' || profile.modules[index].key === 'BiddingSettings'"
                          :unchecked-value="false"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <span class="d-inline cardTitle">Modelo de Conta</span>
                  </div>
                </b-card-title>
                <hr class="mt-1 mb-0">
                <b-card-body
                  class="px-1"
                >
                  <b-row class="px-50 mb-1">
                    <b-col
                      cols="12"
                      md="3"
                    >
                      <account-select
                        id="accountSelect"
                        v-model="profile.account_model_set"
                        :readonly="false"
                        :required-field="true"
                        @ready="syncLoad.ready('accountSelect')"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                    v-for="(item, index) in profile.account_model_settings"
                    :key="item.id"
                    :class="['p-1', index % 2 === 0 ? 'isEven' : '']"
                  >
                    <b-col
                      cols="12"
                      class="d-flex align-items-center"
                    >
                      <span class="moduleName">
                        {{ item.name }}
                      </span>
                      <span class="px-1">
                        <b-form-checkbox
                          :key="item.id"
                          v-model="profile.account_model_settings[index].value"
                          :value="true"
                          :unchecked-value="false"
                        />
                      </span>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-row
                class="mt-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    id="profileSaveButton"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    v-if="!isCreateMode"
                    id="profileCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to="{ name: 'administrator-profiles' }"
                  >
                    Voltar
                  </b-button>
                  <b-button
                    v-if="isCreateMode"
                    id="profileCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to="{ name: 'administrator-profiles' }"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import log from 'loglevel'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import SituationSelect from '@/components/administrator/SituationSelect.vue'
import AccountSelect from '@/components/administrator/AccountSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    SvgIcon,
    ValidationProvider,
    ValidationObserver,
    SpinLoader,
    SituationSelect,
    AccountSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    let isCreateMode = false

    const profile = {
      id: '',
      account_model_request: '',
      account_model_set: null,
      account_model_settings: [
        {
          id: 1,
          name: 'Permissões',
          key: 'Permission',
          value: false,
        },
        {
          id: 2,
          name: 'Configurações de Licitação',
          key: 'BiddingSettings',
          value: false,
        },
        {
          id: 3,
          name: 'Configurações de Pedido',
          key: 'OrderSettings',
          value: false,
        },
        {
          id: 4,
          name: 'Configurações de Contrato',
          key: 'ContractSettings',
          value: false,
        },
        {
          id: 5,
          name: 'Configurações de Fornecedor',
          key: 'SupplierSettings',
          value: false,
        },
        {
          id: 6,
          name: 'Configurações de produto',
          key: 'ProductSettings',
          value: false,
        },
      ],
      modules: [
        {
          id: 1,
          name: 'Licitações',
          key: 'Bidding',
          value: true,
        },
        {
          id: 2,
          name: 'Configurações de Licitações',
          key: 'BiddingSettings',
          value: true,
        },
        {
          id: 3,
          name: 'Contratos',
          key: 'Contract',
          value: false,
        },
        {
          id: 4,
          name: 'Configurações de Contratos',
          key: 'ContractSettings',
          value: false,
        },
        {
          id: 5,
          name: 'Pedidos',
          key: 'Order',
          value: false,
        },
        {
          id: 6,
          name: 'Configurações de Pedidos',
          key: 'OrderSettings',
          value: false,
        },
        {
          id: 7,
          name: 'Comissões',
          key: 'Commission',
          value: false,
        },
        {
          id: 8,
          name: 'Relatórios',
          key: 'Report',
          value: false,
        },
      ],
      name: '',
      situation: 'active',
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.profile)

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('situatioProfile')
    syncLoad.addTrigger('profileAccount')
    syncLoad.addTrigger('accountSelect')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      required,
      isCreateMode,
      profile,
      refFormObserver,
      getValidationState,
      profileId: null,
      isLoaded,
      syncLoad,
    }
  },
  watch: {
    'profile.account_model_set': function updateAccountModel(obj) {
      if (obj) {
        this.profile.account_model_request = obj.id
      } else {
        this.profile.account_model_request = ''
      }
    },
  },
  created() {
    this.profileId = router.currentRoute.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.profileId !== undefined) {
        syslic
          .administrator
          .accountProfile
          .fetchAccountProfile(this.profileId)
          .then(response => {
            this.profile = response.data
            this.syncLoad.ready('profileAccount')
          })
          .catch(error => {
            if (error.status === 404) {
              router.push({ name: 'error-404' })
            }
            this.syncLoad.ready('profileAccount')
          })
      } else {
        this.syncLoad.ready('profileAccount')
      }
    },
    createProfile() {
      syslic
        .administrator
        .accountProfile
        .addAccountProfile(this.profile)
        .then(response => {
          this.profileId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Plano adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'administrator-profiles-edit',
            params: {
              id: this.profileId,
            },
          })
        })
        .catch(() => {
          this.text_error = 'Não foi possível adicionar o plano, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar um novo plano.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateProfile() {
      syslic
        .administrator
        .accountProfile
        .updateAccountProfile(this.profileId, this.profile)
        .then(response => {
          log.info('Update Profile Account')
          this.profile = response.data
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Plano atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.text_error = 'Não foi possível atualiazar o plano, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualiazar o plano.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createProfile()
      } else {
        this.updateProfile()
      }
    },
    deleteProfile(id) {
      syslic
        .administrator
        .accountProfile
        .deleteAccountProfile(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Plano removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'administrator-profiles' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover este plano, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este plano é referenciado, por este motivo não será possível exclui-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover plano.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete() {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover esse plano?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteProfile(this.profileId)
          }
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/base/core/colors/palette-variables.scss";

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

.cardTitle {
  color: #6E6B7B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.isEven {
  background-color: #fafafc;
}

.moduleName {
  min-width: 18rem;
}
</style>
