<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="situation"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        :label="label"
        label-for="situation"
      >
        <v-select
          id="situation"
          v-model="situationSelected"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="options"
          :placeholder="placeholder"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ name, color }">
            <b-badge
              pill
              class="d-inline-flex justify-content-center align-items-center text-capitalize"
              :style="{'background-color': color, 'min-width':'110px'}"
            >
              <span>
                {{ name | truncate(18, '...') }}
              </span>
            </b-badge>
          </template>

          <template #selected-option="{ name, color }">
            <b-badge
              pill
              class="d-inline-flex justify-content-center align-items-center text-capitalize"
              :style="{'background-color': color, 'min-width':'110px'}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #no-options>
            Não há opções de situação do perfil.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'situationValue',
    event: 'situationChange',
  },
  props: {
    situationValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: 'Selecione a Situação',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Selecione a Situação',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const situationSelected = {
      name: 'Ativo',
      color: '#049F14',
      key: 'active',
    }
    const options = [
      {
        name: 'Ativo',
        color: '#049F14',
        key: 'active',
      },
      {
        name: 'Inativo',
        color: '#FF7A00',
        key: 'inactive',
      },
    ]

    return {
      situationSelected,
      options,
      required,
    }
  },
  computed: {
    situation: {
      get() {
        return this.situationValue
      },
      set(value) {
        this.$emit('situationChange', value)
      },
    },
  },
  watch: {
    situationValue: function updateSituationSelected(value) {
      if (value) {
        const [activeOption, inactiveOption] = this.options
        if (value === 'active') {
          this.situationSelected = activeOption
        } else {
          this.situationSelected = inactiveOption
        }
      }
    },
    situationSelected: function updateSituationSelected(value) {
      if (value) {
        this.situation = value.key
      }
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      if (this.situation === 'active') {
        this.situationSelected = {
          name: 'Ativo',
          color: '#049F14',
          key: 'active',
        }
      } else {
        this.situationSelected = {
          name: 'Inativo',
          color: '#FF7A00',
          key: 'inactive',
        }
      }
      this.$emit('ready', true)
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
